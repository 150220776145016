import * as Types from "./types";

const USER_GUIDING_SRC = "https://static.userguiding.com/media/user-guiding-";

class UserGuiding implements Types.IOnboardingManager {
  private id: string;

  constructor(id: string) {
    this.id = id;
  }

  loadScript() {
    if (process.env.NODE_ENV !== "production") return;

    (function initializeUserGuiding(
      global: Window & typeof globalThis,
      doc: Document,
      guidingObjName: string,
      layerName: string,
      userGuidingId: string
    ) {
      const scriptTag = "script";
      const userGuidingSrc = USER_GUIDING_SRC + userGuidingId + "-embedded.js";

      global[layerName] = global[layerName] || [];

      const firstScriptTag = doc.getElementsByTagName(scriptTag)[0];
      const newScriptTag = doc.createElement(scriptTag);

      newScriptTag.async = true;
      newScriptTag.src = userGuidingSrc;
      firstScriptTag.parentNode?.insertBefore(newScriptTag, firstScriptTag);

      if (global[guidingObjName]) return;

      const userGuiding: Record<string, any> = (global[guidingObjName] = {
        q: [],
      });

      userGuiding.createMethod = function (methodName: string) {
        return function (...args: any[]) {
          userGuiding.q.push([methodName, args]);
        };
      };

      const methods = [
        "previewGuide",
        "finishPreview",
        "track",
        "identify",
        "hideChecklist",
        "launchChecklist",
      ];

      methods.forEach((method) => {
        userGuiding[method] = userGuiding.createMethod(method);
      });
    })(window, document, "userGuiding", "userGuidingLayer", this.id);
  }
}

export default UserGuiding;
